<template>
    <div class="dashboard-main">
        <div class="dashboard-main__container">
            <div class="box border border-gray-300 p-5"><strong>Всего судов: </strong> {{ info.shipsNumber }}</div>
            <div class="box border border-gray-300 p-5"><strong>Всего круизов: </strong> {{ info.cruisesNumber }}</div>
            <div class="box border border-gray-300 p-5"><strong>Всего заказов: </strong> {{ info.ordersNumber }}</div>
            <div class="box border border-gray-300 p-5"><strong>Всего страниц: </strong> {{ info.pagesNumber }}</div>
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 mt-2">
            <div class="intro-y mt-5">
                <div class="box p-5">
                    <div class="grid grid-cols-12 gap-4 mb-8">
                        <!--                        <div class="col-span-12 text-2xl font-medium mt-2">Фильтр</div>-->

                        <div class="col-span-4">
                            <input
                                :value="startDate"
                                type="date"
                                class="form-control w-full"
                                autocomplete="off"
                                @input="(e) => handleDateChange(e, 'start_date')"
                            />
                        </div>

                        <div class="col-span-4">
                            <input
                                :value="endDate"
                                type="date"
                                class="form-control w-full"
                                autocomplete="off"
                                @input="(e) => handleDateChange(e, 'end_date')"
                            />
                        </div>

                        <div class="col-span-4">
                            <button type="button" class="btn btn-primary w-full" @click="fetchStatistics">Поиск</button>
                        </div>
                    </div>

                    <div class="flex flex-wrap gap-6">
                        <div class="box border border-gray-300 p-5">
                            <div><strong>Новые пользователи:</strong> {{ statistics?.newUsers || 0 }}</div>
                        </div>

                        <div class="box border border-gray-300 p-5">
                            <div><strong>Новые заказы:</strong> {{ statistics?.newOrders || 0 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const inputDateFormat = 'YYYY-MM-DD';
const filtersDateFormat = 'DD.MM.YYYY';

export default defineComponent({
    setup() {},
    data() {
        return {
            loading: false,
            info: {
                shipsNumber: 0,
                cruisesNumber: 0,
                ordersNumber: 0,
                pagesNumber: 0,
            },
            statistics: null,
            filters: {
                start_date: null,
                end_date: null,
            },
        };
    },
    computed: {
        startDate() {
            return dayjs(this.filters.start_date, filtersDateFormat).format(inputDateFormat);
        },
        endDate() {
            return dayjs(this.filters.end_date, filtersDateFormat).format(inputDateFormat);
        },
    },
    async created() {
        dayjs.extend(customParseFormat);

        this.$store.commit('main/setPageHeader', 'Dashboard');

        this.setFilterDatesOnCreated();
        this.loading = true;
        await this.fetchStat();
        await this.fetchStatistics();
        this.loading = false;
    },
    methods: {
        async fetchStat() {
            try {
                const { data } = await this.axios.get('/dashboard/info');

                if (data.success === true) {
                    this.info = { ...data.data };
                }
            } catch (ex) {
                console.log('cant fetch data: ', ex);
            }
        },
        async fetchStatistics() {
            try {
                const { data } = await this.axios.get('/dashboard/info/filter', {
                    params: {
                        start_date: this.filters.start_date,
                        end_date: this.filters.end_date,
                    },
                });

                if (data.success) {
                    this.statistics = data.data;
                } else {
                    throw new Error('Ошбика сервера');
                }
            } catch (ex) {
                console.log('cant fetch filter: ', ex);
            }
        },
        setFilterDatesOnCreated() {
            const today = dayjs();

            this.filters.start_date = dayjs(`01.${today.month() + 1}.${today.year()}`, filtersDateFormat).format(
                filtersDateFormat
            );
            this.filters.end_date = dayjs().format(filtersDateFormat);
        },
        handleDateChange(e, key) {
            const target = e.target;

            this.filters[key] = target.value ? dayjs(target.value, 'YYYY-MM-DD').format(filtersDateFormat) : null;
        },
    },
});
</script>
<style scoped lang="scss">
.dashboard-main {
    &__container {
        @apply flex flex-wrap gap-6 mt-5;
    }

    &__column {
        flex: 1 1 20rem;
    }

    &__column-title {
        @apply flex items-center font-medium text-base px-5 py-3 text-gray-600 overflow-y-auto;
        max-height: 5rem;
        height: 100%;
    }

    &__empty-message {
        @apply px-5 py-3 bg-white rounded-lg drop-shadow-sm font-bold;
        text-align: center;
    }
}
</style>
